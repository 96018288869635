@import 'variables';

@mixin button-reset {
  /* Only fill the svg white inside the button if the device supports hover and there is a fine pointer  */
  @media (hover: hover) and (pointer: fine) {
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .show > .btn-outline-primary.dropdown-toggle {
      svg {
        fill: $c-white;
      }
    }
  }

  /* Apply default styles for the button in the active and hover case if the device does not support hover */
  @media (hover: none) {
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:hover {
      border-color: $c-choice-button;
      color: $c-choice-button-text;
      background: $c-choice-button-bg;
    }
  }
}
