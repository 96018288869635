@import 'variables';
@import 'mixin.bootstrap';

@mixin widget-box {
  .widget-box {
    background: $c-white;
    border-radius: $border-radius;
    @include shadow;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 14px;
    line-height: normal;
    text-align: center;
    margin: 0 1rem 1rem 1rem;
    padding: 1rem 20%;
    box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.75);

    @include media-breakpoint-up(md) {
      padding: 1rem;
      margin: 1rem;
    }

    h3 {
      margin-bottom: 1rem;
      color: $c-black;
    }

    p {
      margin: 0;
    }

    > div:last-child {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &__tooltip {
      padding: 1rem 0 0;
      font-size: 12px;
      font-weight: 300;
      img {
        display: inline-block;
        width: 16px;
        height: 16px;
      }
    }
  }
}

@mixin widget-box-em {
  .widget-box {
    &__cost,
    &__time-for-full-charge,
    &__charging-power {
      text-align: center;

      div {
        font-weight: 500;
        line-height: normal;
        font-size: 32px;
        color: $c-widget-box-em-text;
      }
    }
  }
}

@mixin widget-box-ht {
  .widget-box {
    &__efficiency {
      div {
        position: relative;
      }

      &-image {
        display: inline-block;
        max-width: 88%;
        width: 88%;
        height: auto;
        min-height: 1px;
      }

      p {
        margin-top: 1rem;
        font-weight: 300;
      }
    }

    &__profitability {
      font-weight: 300;

      &-values {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $c-helion-blue;
        font-weight: 600;
        padding: 0.25rem 0;

        div:first-child {
          font-size: 14px;
          color: lighten($c-widget-box-color, 10%);
          padding: 0 0.5rem 0 0;
        }

        div:last-child {
          font-size: 34px;
          color: $c-widget-box-color;
        }
      }

      p {
        margin-top: 1rem;
        font-weight: 300;
      }
    }
    &__cost {
      text-align: center;

      div {
        font-weight: 500;
        line-height: normal;
      }

      div:first-of-type {
        font-size: 22px;
        color: lighten($c-widget-box-color, 10%);
      }

      div:last-of-type {
        font-size: 34px;
        color: $c-widget-box-color;
      }
    }
    &__cost {
      text-align: center;

      div {
        font-weight: 500;
        line-height: normal;
      }

      div:first-of-type {
        font-size: 22px;
        color: lighten($c-widget-box-color, 10%);
      }

      div:last-of-type {
        font-size: 34px;
        color: $c-widget-box-color;
      }
    }
  }
}

@mixin widget-box-pv {
  .widget-boxes_pv {
    .widget-box {
      &__cost {
        text-align: center;

        small {
          font-size: 22px;
          color: lighten($c-widget-box-color, 10%);
        }

        div {
          font-weight: 500;
          line-height: normal;
        }

        div:first-of-type {
          font-size: 22px;
          color: lighten($c-widget-box-color, 10%);
        }

        div:last-of-type {
          font-size: 34px;
          color: $c-widget-box-color;
        }
      }

      &__efficiency {
        div {
          position: relative;
        }

        &-image {
          width: 88%;
          height: auto;
        }

        p {
          font-weight: 300;
        }

        &-legend {
          color: $c-widget-box-color;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          font-size: 32px;
          font-weight: 500 !important;
          margin: 0;
        }

        &-legend > span {
          font-size: 24px;
        }
      }

      &__profitability {
        font-weight: 300;

        > div:last-child {
          flex-grow: 1;
          display: flex;
          align-items: stretch;
          flex-direction: column;

          > div:last-child {
            .row:first-child {
              color: $c-widget-box-color;
              font-weight: bold;
              padding: 0.5rem 0 0 0;
            }

            .row:last-child {
              font-weight: 300;
            }
          }
        }

        &__profitability-graph {
          height: 20px;
          width: 100%;
        }
      }
    }
  }
}

@mixin widget-box-pvx {
  .widget-boxes_pvx {
    .widget-box {
      &__cost {
        small {
          font-size: 22px;
          color: lighten($c-widget-box-color, 10%);
        }

        div:last-of-type {
          font-size: 34px;
          color: $c-widget-box-color;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      &__efficiency {
        text-align: center;

        div {
          font-weight: 500;
          line-height: normal;
        }

        small {
          font-size: 22px;
          color: lighten($c-widget-box-color, 10%);
        }

        div:last-of-type {
          font-size: 34px;
          color: $c-widget-box-color;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      &__profitability {
        text-align: center;

        div {
          font-weight: 500;
          line-height: normal;
        }

        small {
          font-size: 22px;
          color: lighten($c-widget-box-color, 10%);
        }

        div:last-of-type {
          font-size: 34px;
          color: $c-widget-box-color;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}
