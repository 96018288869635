@import 'variables';

@mixin icon {
  app-icon {
    .icon {
      color: $c-icon;
      fill: $c-icon;
      display: flex;
      justify-content: center;
      align-items: center;

      &__inline {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0.2rem;
      }

      &__var {
        &,
        svg {
          object-fit: contain;
        }
      }

      &__16 {
        &,
        svg {
          width: 1rem;
          height: 1rem;
        }
      }

      &__20 {
        &,
        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      &__24 {
        &,
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &__32 {
        &,
        svg {
          width: 2rem;
          height: 2rem;
        }
      }

      &__48 {
        &,
        svg {
          width: 3rem;
          height: 3rem;
        }
      }

      &__64 {
        &,
        svg {
          width: 4rem;
          height: 4rem;
        }
      }

      &__72 {
        &,
        svg {
          width: 4.5rem;
          height: 4.5rem;
        }
      }

      // reset width to parent container
      &__inherit-width {
        &,
        svg {
          width: inherit;
        }
      }
    }
  }
}
