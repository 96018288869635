@import 'variables';
@import 'mixin.bootstrap';
@import 'mixin.interactive';

@mixin form {
  $label-height: 2rem;
  $input-height: 2.25rem;

  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    padding-right: 0;
    background-image: none;
  }

  .app-input {
    position: relative;

    label {
      font-size: 14px;
      margin-bottom: 0.25rem;
      color: $c-black;
    }

    &--required label:after {
      content: '*';
      position: relative;
      left: 4px;
      top: 0;
      font-size: 1.2rem;
    }

    &--invalid label:after {
      color: $c-error;
    }

    a {
      margin-left: 20px;
      font-size: 14px;
      margin-bottom: 0.25rem;
      color: $link-color;
      cursor: pointer;
    }

    &__field-wrapper {
      position: relative;
    }

    &__input-wrapper {
      position: relative;

      input {
        padding-right: 5rem;
      }
      input:disabled {
        background-color: $c-white;
      }
    }

    &__icons {
      position: absolute;
      top: 0;
      right: 0.5rem;
      display: flex;
      align-items: center;
      height: 100%;

      svg {
        cursor: pointer;
      }
    }

    &__indicator {
      position: absolute;
      right: -1.2rem;
      top: 0;
      height: 100%;
      width: 1rem;
      display: flex;
      align-items: center;

      app-icon .icon__32 {
        width: 1rem;
        height: 1rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          fill: $c-success;
        }
      }

      &--invalid {
        app-icon .icon__32 svg {
          fill: $c-error;
        }
      }
    }

    // disable numeric input spinner/arrows in webkit and firefox
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .radio-buttons {
    position: relative;

    &.required > label {
      position: relative;
    }

    &.required > label:after {
      content: '*';
      position: absolute;
      right: -0.5rem;
      top: -0.25rem;
      font-size: 1.2rem;
    }

    &.invalid > label:after {
      color: $c-error;
    }

    > label {
      font-size: 14px;
    }

    label {
      @include unselectable;
    }

    &__options {
      &--inline {
        display: flex;
        div {
          margin-right: 1rem;
        }
      }
    }
  }

  app-checkbox {
    position: relative;
    display: block;
    label {
      @include unselectable;
    }

    .custom-control-label span {
      position: relative;
    }

    .custom-control.required .custom-control-label span:after {
      content: '*';
      position: absolute;
      right: -0.5rem;
      bottom: 0;
      font-size: 1.2rem;
    }

    .custom-control.invalid .custom-control-label span:after {
      color: $c-error;
    }

    //.custom-checkbox.invalid {
    //  background-color: #f9e1e0;
    //  color: #903430;
    //}
  }

  .custom-control.custom-radio {
    padding-left: 0;
    margin-right: 1.5rem;

    .custom-control-label {
      padding-top: 0.6rem;
      padding-left: 2.75rem;
      font-size: 14px;
      line-height: 1;
      min-height: 2rem;
    }

    //unchecked
    .custom-control-label::before {
      left: 0;
      width: 27px;
      height: 27px;
      border: 1px solid $c-custom-control-unchecked;
      border-radius: 50%;
    }

    //checked
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $c-white;
      border: 1px solid $c-custom-control-active;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      left: 6px;
      top: 10px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $c-custom-control-active;
      background-image: none;
    }
  }

  .custom-control-input:checked ~ .custom-control-label:before {
    border-color: $c-checkbox-active;
    background-color: $c-checkbox-active;
  }

  @include media-breakpoint-up(md) {
    .custom-control.custom-radio {
      margin-right: 1.5rem;

      .custom-control-label {
        padding-top: 0.6rem;
        padding-left: 2.3rem;
        font-size: 14px;
        line-height: 1;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .custom-control.custom-radio {
      margin-right: 0.7rem;

      .custom-control-label {
        padding-top: 0.6rem;
        padding-left: 2.2rem;
        font-size: 14px;
        line-height: 1;
      }
    }
  }

  .form-row > .col:not(:first-child),
  .form-row > [class*='col-']:not(:first-child) {
    padding-left: 10px;
  }

  .form-row > .col:first-child,
  .form-row > [class*='col-']:first-child {
    padding-right: 10px;
  }

  .form-row.form-row__input-group > .col:not(:first-child),
  .form-row.form-row__input-group > [class*='col-']:not(:first-child) {
    padding-left: 0;
    margin-left: -1px;

    & .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .form-row.form-row__input-group > .col:not(:last-child),
  .form-row.form-row__input-group > [class*='col-']:not(:last-child) {
    & .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    & .app-input__indicator {
      display: none;
    }
  }

  .form-row.form-row__input-group > .col:first-child,
  .form-row.form-row__input-group > [class*='col-']:first-child {
    padding-right: 0;
  }

  .form-row.form-row__input-group .app-input--touched {
    z-index: 3;
  }
}
