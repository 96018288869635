@import 'variables';

@mixin offer-preview-loading-spinner {
  $size: 210px;

  app-offer-preview-loading-spinner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;

    .offer-preview-loading-spinner {
      position: relative;
      width: $size;
      height: $size;

      &__image {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $size;
        width: $size - 30px;
        height: $size - 30px;
        background-size: cover;
        background-position: center center;
        margin: 15px;
        z-index: 3001;
      }

      @keyframes rotation {
        from {
          transform: rotate(359deg);
        }
        to {
          transform: rotate(0deg);
        }
      }

      &__scanner {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $size;
        width: $size - 30px;
        height: $size - 30px;
        background-size: cover;
        background-position: center center;
        margin: 15px;
        background-image: url('../assets/img/loading-spinner-scanner-overlay.png');
        z-index: 3002;
        animation: rotation 1s infinite ease-in-out;
      }

      &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3003;
        width: 100%;
        height: 100%;
      }

      &__caption {
        text-align: center;
        font-size: 17px;
        line-height: 1;
        padding-top: 1rem;
        margin-top: $size;
        > div:first-child {
          font-weight: 700;
          color: $c-offer-preview-loading-spinner;
        }
        > div:last-child {
          font-weight: 300;
        }
      }
    }
  }
}
