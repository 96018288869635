@import 'variables';
@import 'mixin.bootstrap';
@import 'mixin.style';
@import 'mixin.pricing-table';
@import 'mixin.widget-box';

@mixin offer-preview-pv {
  .offer-preview-pv {
    align-items: stretch;
    background: $c-white;

    &__sidebar {
      background: $c-offer-preview-sidebar-bg;
      padding: 1rem 0 1rem 0.5rem;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__sidebar-note {
      text-align: center;
      margin-top: 24px;
      color: $c-grey-light;

      small {
        font-size: 14px;
      }
    }

    &__fake-bg {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      left: -100%;
      background: $c-offer-preview-sidebar-bg;
    }

    &__topbar {
      background: $c-offer-preview-sidebar-bg;
      padding: 0;

      .swiper-slide {
        padding: 1rem 1.5rem 0;
      }

      .swiper-button-next,
      .swiper-button-prev {
        transform: scale(0.4);
      }

      .swiper-button-next {
        right: 0;
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &__main {
      padding: 0.75rem 0.75rem (2 * $navbar-height);
      background: $c-white;

      @include media-breakpoint-down(sm) {
        padding-bottom: $navbar-height;
      }

      > div {
        margin-bottom: 2rem;

        @include media-breakpoint-down(sm) {
          margin-bottom: 2.5rem;
        }
      }

      @include media-breakpoint-up(md) {
        padding: 1rem 1.5rem (2 * $navbar-height);
      }

      @include pricing-table;
      @include widget-box-pv;
      @include widget-box-pvx;
      @include widget-box;
    }

    &__template {
      background: $c-grey-lightest;
      border-radius: 3px;
      margin-left: initial;
      margin-right: initial;

      & > div {
        padding: 0.75rem 1rem 0.75rem 0.75rem;
      }
    }

    &__template-image {
      position: relative;
      border-radius: 3px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__template-image-button {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $c-template-image-button;
      width: 42px;
      height: 42px;
      border-top-left-radius: 42px;
      padding: 12px 0 0 12px;

      svg {
        fill: $c-white;
      }
    }

    &__template-description {
      padding: 0.75rem 1rem;
      @include div-list-bullets;
    }

    &__widget-box-container {
      align-items: stretch;
    }

    .widget-box-container {
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }

    .summary {
      &__preview-image img {
        width: 100%;
        height: auto;

        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        > div {
          width: 50%;
          margin: 0 0 0.66rem;
          padding: 0 0 0 1.5rem;
          position: relative;
          font-weight: 300;
          font-size: 14px;
        }

        > div:before {
          content: '›';
          display: block;
          position: absolute;
          top: -0.9rem;
          left: 0.5rem;
          width: 1rem;
          height: 1rem;
          border-radius: 0.5rem;
          color: $c-summary-list-item-arrow;
          font-size: 32px;
          font-weight: 500;
        }
      }
    }

    .bottom-box {
      &__image {
        img {
          width: 100%;
          height: auto;
        }
      }

      &__content {
        @include div-list-bullets;
      }
    }

    .buttons {
      .row:last-child {
        margin-top: 0.5rem;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      .btn {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    .success-story {
      & > * {
        font-weight: 300;
      }

      &__image {
        max-width: 100%;
        padding-top: 0.5rem;
      }
    }
  }

  .ratio-graph-pv {
    width: 100%;

    &__graph {
      position: relative;
    }

    &__bars {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
      position: relative;
    }

    &__left-bar,
    &__right-bar {
      position: relative;
      height: 50%;
      width: 45%;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
      color: $c-white;
      padding: 0 0 0.5rem;
    }

    &__left-bar {
      margin: 0 5% 0 0;
      background: $c-ratio-graph-pv-left-bar;
    }

    &__right-bar {
      margin: 0 0 0 5%;
      background: $c-ratio-graph-pv-right-bar;
    }

    &__horizontal-line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top: 2px dashed $c-ratio-graph-pv-horizontal-line;
    }
  }

  .offer-preview-selected-area {
    .col:last-child {
      flex-grow: 0;
    }

    .col:first-child {
      position: relative;
      margin: 0 0 0 1rem;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        display: flex;
        align-items: center;
      }
    }

    &__indicator {
      &--bg {
        background: $c-grey-lighter;
      }

      &--selected {
        background: $c-selected-area-indicator;
      }

      &--legend {
        font-weight: bold;
        color: $c-white;
        padding: 0 0 0 1rem;
      }
    }
  }
}

.contact-modal {
  max-width: 800px;
  max-height: calc(100vh - 1rem);

  @media screen and (max-width: $breakpoint-small) {
    margin: 0;
  }

  @media screen and (min-width: $breakpoint-small) {
    max-height: calc(100vh - 56px);
  }

  .modal-content {
    max-height: inherit;
    overflow-y: auto;
  }

  .close {
    font-size: 2rem;
  }
}

.contact-modal-privacy-tooltip {
  text-align: left;
  ul {
    padding-left: 1rem;
  }
}
