@import 'variables';

@mixin roof-3d {
  app-roof-3d {
    display: block;
    position: relative;
    z-index: 0;
    width: 100%;

    small {
      font-size: 12px;
    }

    &.app-roof-3d-ht .card-body {
      padding: 0;
    }

    &.app-roof-3d-pv .card-body {
      padding: 1rem 0.5rem 1rem 0.5rem;
    }

    #plugin-viewer {
      position: relative;

      canvas {
        display: block;
      }

      #playerZoomInButton,
      #playerZoomOutButton,
      #playerRotateRight,
      #playerRotateLeft {
        img {
          width: 100%;
          height: auto;
          margin-bottom: 0.75rem;
        }
      }
    }
  }
}

.roof-3d {
  .card-body {
    padding: 1rem 0.5rem 1rem 0.5rem;
  }

  small {
    font-size: 12px;
  }

  &__color-indicator {
    height: 6px;
    width: 100%;
    background-color: #28db36;

    &--low {
      background-color: #fc3200;
    }

    &--medium {
      background-color: #ffa500;
    }
  }

  &__color-indicators {
    gap: 4px;
    margin: 0.25rem 0;
  }

  &__area-indicator {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.compass-down {
  #kompass {
    top: 4rem !important;
  }
}
