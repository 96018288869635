@import 'variables';
@import 'mixin.bootstrap';
@import 'mixin.style';
@import 'mixin.pricing-table';
@import 'mixin.widget-box';

@mixin offer-preview-ht {
  .offer-preview-ht {
    align-items: stretch;
    background: $c-white;

    &__sidebar {
      background: $c-offer-preview-sidebar-bg;
      padding: 1rem 0 1rem 0.5rem;

      &--desktop {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &__sidebar-caption {
      @include media-breakpoint-down(sm) {
        margin-left: 1.5rem;
      }
    }

    &__sidebar-note {
      text-align: center;
      margin-top: 24px;
      color: $c-grey-light;

      small {
        font-size: 14px;
      }
    }

    &__fake-bg {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      left: -100%;
      background: $c-offer-preview-sidebar-bg;
    }

    &__topbar {
      background: $c-offer-preview-sidebar-bg;
      padding: 0;

      .swiper-slide {
        padding: 1rem 1.5rem 0;
      }

      .swiper-button-next,
      .swiper-button-prev {
        transform: scale(0.4);
      }

      .swiper-button-next {
        right: 0;
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
      }

      &--mobile {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    &__main {
      padding: 0.75rem 0.75rem (2 * $navbar-height);
      background: $c-white;

      > div {
        margin-bottom: 2rem;

        @include media-breakpoint-down(sm) {
          margin-bottom: 2.5rem;
        }
      }

      @include media-breakpoint-up(md) {
        padding: 1rem 1.5rem (2 * $navbar-height);
      }

      @include pricing-table;
      @include widget-box;
      @include widget-box-ht;
    }

    &__template {
      background: $c-grey-lightest;
      border-radius: 3px;
      margin-left: initial;
      margin-right: initial;

      & > div {
        padding: 0.75rem 1rem 0.75rem 0.75rem;
      }
    }

    &__template-image {
      position: relative;
      border-radius: 3px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__template-image-button {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $c-template-image-button;
      width: 42px;
      height: 42px;
      border-top-left-radius: 42px;
      padding: 12px 0 0 12px;

      svg {
        fill: $c-white;
      }
    }

    &__template-description {
      padding: 0.75rem 1rem;
      @include div-list-bullets;
    }

    &__ar-viewer-link-wrapper {
      padding: 0.25rem 1rem;
    }

    &__widget-box-container {
      align-items: stretch;
    }

    .widget-box-container {
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }

    .summary {
      &__preview-image img {
        width: 100%;
        height: auto;

        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        > div {
          width: 50%;
          margin: 0 0 0.66rem;
          padding: 0 0 0 1.5rem;
          position: relative;
          font-weight: 300;
          font-size: 14px;
        }

        > div:before {
          content: '›';
          display: block;
          position: absolute;
          top: -0.9rem;
          left: 0.5rem;
          width: 1rem;
          height: 1rem;
          border-radius: 0.5rem;
          color: $c-summary-list-item-arrow;
          font-size: 32px;
          font-weight: 500;
        }

        > div > div:first-child {
          display: none;
        }
      }
    }

    // hybrid view
    &__sidebar {
      .summary {
        margin-top: 2rem;
      }
      .summary__list > div {
        width: 100%;
      }
    }
    // hybrid view

    .bottom-box {
      &__image {
        img {
          width: 100%;
          height: auto;
        }
      }

      &__content {
        @include div-list-bullets;
      }
    }

    .buttons {
      .row:last-child {
        margin-top: 0.5rem;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      .btn {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    .tab-button {
      @include media-breakpoint-up(md) {
        border-width: 0.375rem;
        border-style: solid;
        border-right-style: none;
      }

      @include media-breakpoint-down(sm) {
        border-width: 0.375rem;
        border-style: solid;
        margin: 0 0 0.5rem 0;
      }
    }
  }

  .offer-preview-modal-tank-dismantle {
    &:hover {
      cursor: pointer;
    }

    &__modal {
      max-width: 750px;
    }

    &__modal-body {
      .radio-buttons > label {
        font-size: 14px;
        font-weight: 700;
      }

      .custom-radio {
        padding-bottom: 1rem;
      }
    }

    .offer-preview-modal-tank-dismantle__modal-footer {
      justify-content: space-between;
    }
  }

  .offer-preview-heatpump-type-radiobutton {
    margin-top: 1.5rem;
    @include media-breakpoint-down(sm) {
      margin-left: 1.5rem;
    }

    .custom-radio {
      padding-bottom: 1rem;

      //& .custom-control-input:checked ~ .custom-control-label::before {
      //  border-color: $c-helion-blue-light;
      //}
      //
      //& .custom-control-input:checked ~ .custom-control-label::after {
      //  background-color: $c-helion-blue-light;
      //}

      & .custom-control-label {
        display: block;
        clear: both;
      }

      .recommended-heat-pump-type {
        text-align: right;
        color: $c-helion-orange;
        float: right;
        position: relative;
        font-size: 0.85rem;

        @include media-breakpoint-down(md) {
          display: none;
        }

        &::before {
          display: block;
          color: $c-helion-orange;
          content: '';
          width: 0.75rem;
          height: 0.75rem;
          position: absolute;
          left: -1.25rem;
          background: url('../assets/img/icn-star.svg') no-repeat center;
          background-size: 0.75rem;
        }
      }
    }

    &__options {
      margin-left: 1rem;
    }
  }
}
