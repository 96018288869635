@import 'variables';
@import 'mixin.bootstrap';
@import 'mixin.style';

$byes-blue: #0093bb;
$c-offer-preview-em-title: black !default;
$c-offer-preview-em-tab-title: #4a4a4a !default;

@mixin offer-preview-custom-em {
  .offer-preview-custom-em {
    &__content-wrapper {
      padding-top: 2rem;
      padding-bottom: 10.5rem;
    }

    &__template_options_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__template_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
      }
    }

    &__two-col-row-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
      width: 100%;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      &__col {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }

    &__modal-images-wrapper {
      margin-left: auto;
      margin-right: auto;
      max-width: 275px;

      @include media-breakpoint-up(sm) {
        max-width: 250px;
      }
    }

    &__template-image {
      position: relative;
      border-radius: 3px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__template-image-button {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $c-template-image-button;
      width: 42px;
      height: 42px;
      border-top-left-radius: 42px;
      padding: 12px 0 0 12px;

      svg {
        fill: $c-white;
      }
    }

    &__template-description {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      @include div-list-bullets;

      h3 {
        color: $c-offer-preview-em-title;
      }
    }

    &__template_options-note {
      width: 100%;
      text-align: left;
      margin-top: 24px;
      color: $c-grey-light;

      @include media-breakpoint-up(md) {
        text-align: center;
      }

      small {
        font-size: 14px;
      }
    }

    .tab-button {
      $c: &;

      @include media-breakpoint-down(sm) {
        border-top-color: $c-tab-white-inactive;
      }

      &__icon {
        height: 7rem;
      }

      &__icon-image {
        border: none;
      }

      &--active,
      &:hover {
        .tab-button__icon svg {
          fill: $byes-blue;
        }

        .tab-button__icon-image {
          border: none;
        }
      }

      &__caption {
        h3 {
          color: $c-offer-preview-em-tab-title;
        }
      }
    }

    .summary {
      &__preview-image img {
        width: 100%;
        height: auto;

        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        > div {
          width: 50%;
          margin: 0 0 0.66rem;
          padding: 0 0 0 1.5rem;
          position: relative;
          font-weight: 300;
          font-size: 14px;
        }

        > div:before {
          content: '›';
          display: block;
          position: absolute;
          top: -0.9rem;
          left: 0.5rem;
          width: 1rem;
          height: 1rem;
          border-radius: 0.5rem;
          color: $c-summary-list-item-arrow;
          font-size: 32px;
          font-weight: 500;
        }
      }
    }

    .bottom-box {
      margin-top: 2rem;

      &__image {
        img {
          width: 100%;
          height: auto;
        }
      }

      &__content {
        @include div-list-bullets;
      }
    }
  }
}
