/* Variables */

// Colors
$c-charcoal: #444;
$c-silver: #aaa;

$c-success: rgb(77, 153, 0);
$c-error: #f93c53;
$c-yellow: #f5ff22;

$c-very-light-pink: rgb(235, 235, 235);
$c-brownish-grey: rgb(100, 100, 100);
$c-black: rgb(29, 29, 27) !default;
$c-brown-grey: rgb(154, 154, 154);
$c-very-light-pink: rgb(225, 225, 225);

$c-helion-orange: #f5a623 !default;
$c-helion-blue-light: #00cdff !default;
$c-helion-blue: #003250 !default;

$c-grey: #646464 !default;
$c-grey-light: #9a9a9a !default;
$c-grey-lightest: #ebebeb !default;

$c-black: #1d1d1b !default;
$c-snow: #f8f8f8 !default;
$c-grey-lighter: #e1e1e1 !default;
$c-white: #ffffff !default;
$c-white-yellow: #fff0d6 !default;
$c-tab-white-inactive: #f8f8f8 !default;

$c-primary: $c-helion-blue !default;
$c-secondary: $c-helion-orange !default;

$theme-colors: (
  'primary': $c-helion-blue,
  'secondary': $c-helion-orange,
  'danger': $c-error,
  'light': $c-snow,
) !default;

// application vars
$c-title: $c-helion-blue !default;
$c-icon: $c-helion-blue-light !default;
$c-progress-bar-active: $c-helion-blue-light !default;
$c-progress-bar-inactive: $c-grey-lighter !default;
$c-header-phone: $c-helion-orange !default;
$c-headerbar-border: $c-grey-lighter !default;

$c-footer: $c-helion-blue-light !default;
$c-footer-bg: $c-helion-blue !default;
$c-footer-arrow: $c-helion-blue-light !default;
$c-footer-share-button: $c-white !default;

$c-choice-button: $c-helion-blue-light !default;
$c-choice-button-bg: $c-white !default;
$c-choice-button-text: inherit !default;
$c-choice-button-text-hover: inherit !default;
$c-choice-button-hover: $c-helion-orange !default;
$c-choice-button-icon-hover: $c-helion-orange !default;
$c-choice-button-bg-hover: $c-white-yellow !default;

$c-custom-control-unchecked: $c-helion-blue !default;
$c-custom-control-active: $c-helion-orange !default;
$c-checkbox-active: $c-custom-control-active !default;

$c-ui-switch: $c-helion-blue-light !default;
$c-ui-switch-active: $c-helion-orange !default;
$c-ui-switch-active-border: $c-ui-switch-active !default;
$c-ui-switch-active-bg: $c-ui-switch-active !default;
$c-offer-preview-switch-active-bg: transparentize($c-ui-switch-active, 0.9) !default;
$c-tab-button-left-border: $c-white !default;
$c-tab-button-bg: $c-white !default;
$c-tab-button-active-bg: $c-tab-white-inactive !default;
$c-tab-button-active: $c-helion-orange !default;
$c-tab-button: $c-helion-blue-light !default;
$c-roof-3d-legend-icon: #ff00b7 !default;
$c-widget-box-color: $c-helion-blue-light !default;
$c-selected-area-indicator: $c-helion-orange !default;
$c-modal-header: $c-helion-orange !default;

$c-ngx-slider-active: $c-helion-orange !default;
$c-ratio-slider: $c-helion-orange !default;

$c-ratio-graph-pv-left-bar: $c-helion-blue-light !default;
$c-ratio-graph-pv-right-bar: $c-helion-orange !default;
$c-ratio-graph-pv-horizontal-line: $c-helion-blue-light !default;

$c-ratio-graph-transparent-text: $c-helion-blue !default;
$c-ratio-graph-left-bar: $c-helion-blue-light !default;
$c-ratio-graph-right-bar: $c-helion-orange !default;
$c-ratio-graph-horizontal-line: $c-helion-blue-light !default;

$c-pricing-table-border: $c-helion-blue-light !default;
$c-pricing-table-total: $c-helion-blue !default;
$c-summary-list-item-arrow: $c-helion-blue-light !default;
$c-template-image-button: $c-helion-blue-light !default;
$c-offer-preview-loading-spinner: $c-helion-blue !default;
$c-offer-preview-sidebar-bg: $c-grey-lighter !default;
$c-widget-box-em-text: inherit !default;

$c-address-typeahead-active-bg: $c-helion-blue-light !default;

$c-comprasion-energy-cost: #ffc360 !default;
$c-comprasion-maintenance-cost: $c-yellow !default;
$c-comprasion-depreciation-cost: #92bddd !default;
$c-comprasion-cheapest: #4d9900 !default;
$c-comprasion-selected-type-color: $c-secondary !default;
$c-comprasion-selected-type-color-lighten: lighten($c-comprasion-selected-type-color, 35) !default;
$c-comprasion-selected-type-color-lighten-more: lighten(
  $c-comprasion-selected-type-color,
  44
) !default;

$fill-color-icn-bullet-arrow: $c-helion-blue-light !default;

$font-family-base: 'Proxima Nova', Arial, sans-serif;

// Layout
$breakpoint-medium: 1024px;
$breakpoint-medium-2: 991px;
$breakpoint-small: 768px;
$content-width-l: 800px;
$content-width-m: 100%;
$content-width-s: 100%;
$space-unit: 0.25rem;

// Typography
$font-family-italic: 'Proxima Nova', Arial, sans-serif;
$font-family-regular: 'Proxima Nova', Arial, sans-serif;
$font-family-semibold: 'Proxima Nova', Arial, sans-serif;

$font-size-xxx-large: 2.25rem;
$font-size-xx-large: 1.625rem;
$font-size-x-large: 1.25rem;
$font-size-large: 1.125rem;
$font-size-normal: 1rem;
$font-size-small: 0.875rem;
$font-size-x-small: 0.75rem;

// Measurements

$headerbar-height: 6.25rem;
$navbar-height: 5.25rem;
$navbar-mobile-height: 3.5rem;

$border-radius: 2px !default;

// Input
$input-border-radius: $border-radius;
$input-border-width: 1px;
$input-border-color: $c-black;
$input-border-disabled: $c-grey;
$input-color-disabled: $c-silver;

// Z-Index
$z-index-overlay: 9999;

// Translucency
$translucent-element: 0.85;
$transparent-element: 0.5;

$div-list-bullets-styles: () !default;
$div-list-bullets-styles-before: () !default;
