@import 'variables';

@mixin shadow {
  box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.5);
}

@mixin div-list-bullets {
  div {
    margin: 0 0 0 1.75rem;
    position: relative;

    @each $property, $value in $div-list-bullets-styles {
      #{$property}: $value;
    }
  }

  div:before {
    --arrow-icon: url('../assets/icons/var/icn-bullet-arrow.svg');
    margin: 0 0 0 -1.75rem;
    content: ' ';
    display: block;
    position: absolute;
    top: 0.2rem;
    left: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: $fill-color-icn-bullet-arrow;
    mask-image: var(--arrow-icon);
    -webkit-mask-image: var(--arrow-icon);

    @each $property, $value in $div-list-bullets-styles-before {
      #{$property}: $value;
    }
  }
}
