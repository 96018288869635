@import 'variables';

@mixin unselectable {
  user-select: none;
  cursor: default;
}

@mixin interactive($is-interactive: true) {
  @if $is-interactive == true {
    // boolean check not string
    &:hover,
    &:active {
      box-shadow: 0px 0.125rem 0.25rem 0px $c-silver;
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  } @else {
    &:hover,
    &:active {
      box-shadow: none;
      cursor: default;
      pointer-events: none;
    }
  }
}

@mixin clickable {
  &:hover,
  &:active {
    cursor: pointer;
    color: $c-helion-blue-light;
  }
}

@mixin clickable-inverted {
  &:hover,
  &:active {
    cursor: pointer;
    color: $c-snow;
    svg {
      fill: $c-snow;
    }
  }
}
