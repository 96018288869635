@import 'variables';

@mixin comparison-large {
  // hack to keep desktop usable (slightly) on mobile
  body.comparison app-steps {
    overflow-x: scroll;
    min-width: 940px;
  }

  .comparison-large {
    padding-bottom: 10rem;

    .container {
      min-width: 940px;
    }

    .is-sticky {
      z-index: 10;
      height: 40px !important;

      .icon.icon__48 {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &__header {
      background: $c-primary;
      padding: 1.2rem 0;
      margin: 0 0 2rem 0;

      p {
        font-weight: 600;
        color: $c-helion-blue-light;
        margin: 0;
      }

      h1 {
        font-weight: 300;
        color: $c-white;
        margin: 0;
      }
    }

    &__feature-table {
      font-size: 12px;
      text-align: center;
      margin-bottom: 3rem;
    }

    &__feature-table-title {
      font-size: 16px;
      text-align: left;
      color: $c-primary;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    &__feature-table-body {
      .col {
        padding: 2px 0 0 0;
        border-bottom: 2px solid $c-grey-lighter;

        &.header-only {
          border-top-width: 0;
          border-bottom-width: 0;
          border-color: transparent;
        }

        & > div {
          padding: 0.5rem;
          background: $c-white;
          border-right: 2px solid $c-grey-lighter;
        }

        & > div:nth-child(2n) {
          background: $c-snow;
        }

        & > div:nth-child(1) {
          visibility: hidden;
        }

        & > div:nth-child(2) {
          background: $c-primary;
          color: $c-white;
          font-weight: 600;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          text-align: left;

          > app-icon .icon {
            fill: $c-white;
            opacity: 0.5;
          }

          > *:last-child {
            padding-left: 0.5rem;
          }
        }
      }

      .col > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .col-selected {
        padding: 0;
        border-top: 2px solid $c-comprasion-selected-type-color;
        border-left: 2px solid $c-comprasion-selected-type-color;
        border-right: 2px solid $c-comprasion-selected-type-color;
        border-bottom: 2px solid $c-comprasion-selected-type-color;

        & > div {
          border: none;
        }

        & > div:nth-child(2n) {
          background: $c-comprasion-selected-type-color-lighten;
        }

        & > div:nth-child(2n-1) {
          background: $c-comprasion-selected-type-color-lighten-more;
        }

        & > div:nth-child(1) {
          visibility: visible;
          font-weight: 700;
          color: $c-secondary;
        }

        & > div:nth-child(2) {
          background: $c-comprasion-selected-type-color;
        }
      }

      .col:first-child {
        text-align: right !important;
        font-weight: 600;
        border: none;
        background: $c-snow;
        padding-left: 8px;

        &.header-only {
          background-color: transparent;
        }

        & > div {
          background: transparent;
          border: none;
        }
      }
    }

    &__costsplit-table {
      font-size: 14px;
      margin-bottom: 3rem;

      .col {
        padding: 0;
      }
    }

    &__costsplit-table-title {
      font-size: 16px;
      text-align: left;
      color: $c-primary;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    &__costsplit-table-body {
    }

    &__costsplit-table-legend-container {
      &.col {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 2rem 0 7rem;
      }

      > div {
        padding: 0 0 0 1rem;
        display: inline-flex;

        & span:last-child {
          flex-shrink: 0;
          margin-left: 4px;
        }
      }
    }

    &__costsplit-table-legend {
      height: 12px;
      width: 12px;
      display: block;
      float: left;
      margin: 0.25rem 0.5rem 1rem 0;
      flex-shrink: 0;

      &--energy-cost {
        background: $c-comprasion-energy-cost;
      }

      &--maintenance-cost {
        background: $c-comprasion-maintenance-cost;
      }

      &--depreciation-cost {
        background: $c-comprasion-depreciation-cost;
      }
    }
  }

  app-comparison-split-graph {
    height: 100%;
    padding-bottom: 4.5rem;
    display: block;
  }
  .comparison-split-graph {
    height: 100%;
    min-height: 280px;

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      padding: 0 10px;
      border: 2px solid transparent;
    }

    &--selected-col {
      border: 2px solid $c-comprasion-selected-type-color;
      background: $c-comprasion-selected-type-color-lighten;

      .comparison-split-graph__header {
        font-weight: 700;
        color: $c-secondary;
      }
    }

    &--cheapest-col {
      .comparison-split-graph__inner {
        border: 1px solid $c-comprasion-cheapest;
        border-top-width: 5px;
      }

      .comparison-split-graph__header {
        font-weight: 700;
        color: $c-comprasion-cheapest;
      }
    }

    &--selected-and-cheapest-col {
      border: 2px solid $c-secondary;
      background: lighten($c-secondary, 38);

      // Don't show green header bar
      //border-top-width: 5px;
      //border-top-color: $c-comprasion-cheapest;

      .comparison-split-graph__header {
        font-weight: 700;
        color: $c-comprasion-cheapest;
      }
    }

    &__header {
      height: 2rem;
      padding: 0.5rem 0 0 0;
      text-align: center;
      font-size: 12px;
    }

    &__inner {
      border: 1px solid transparent;
      border-top-width: 5px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__graph-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
    }

    &__scaled {
      height: 100%;
      padding: 1rem 10px 0;
      box-sizing: content-box;
    }

    &__energy-cost,
    &__maintenance-cost,
    &__depreciation-cost,
    &__total-cost {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__energy-cost {
      height: 33.3%;
      background: $c-comprasion-energy-cost;
    }

    &__maintenance-cost {
      height: 33.3%;
      background: $c-comprasion-maintenance-cost;
    }

    &__depreciation-cost {
      height: 33.3%;
      background: $c-comprasion-depreciation-cost;
    }

    &__total-cost {
      font-weight: 700;
      color: $c-primary;
      padding: 1rem 0;
      line-height: 0.8;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;

      .btn {
        font-size: 14px;
      }
    }
  }

  .comparison-settings {
    background: $c-grey-lightest;
    padding: 2rem 2.5rem;

    &__arrow-container {
      display: flex;
      justify-content: center;
      margin-top: -2.75rem;
    }

    &__arrow {
      width: 28px;
      height: 28px;
      transform: rotate(45deg);
      background-color: $c-grey-lightest;
    }

    &__title {
      p:first-child {
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: $c-primary;
      }

      p:last-child {
        font-size: 14px;
        margin: 0;
      }
    }

    &__toggle-container {
      font-size: 14px;
    }

    &__toggle {
      display: flex;
    }

    &__sliders {
      padding: 1rem 0 0 0;

      > div {
        padding: 0.5rem 0;
        position: relative;
      }

      @include ngx-slider;

      .ngx-slider {
        margin: 1.5rem 0 2rem;
      }

      .ngx-slider .ngx-slider-bubble.ngx-slider-limit {
        top: 1rem;
      }

      .ngx-slider[disabled] {
        .ngx-slider-pointer {
          border-color: $c-grey-lighter;
          background: $c-white;
          opacity: 0.5;
        }
      }
    }

    &__slider-title {
      font-size: 14px;

      strong:first-child {
        margin-right: 1rem;
      }
    }

    &__slider-power--deactivated {
      position: absolute;
      top: 1rem;
      right: 0;
      left: 0;
      bottom: 0;
      // background: transparentize($c-grey-lightest, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      > div {
        background: $c-secondary;
        color: $c-white;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        width: 100%;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .preset-button {
      user-select: none;
      border-radius: 3px;
      box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.5);
      border: solid 1px rgba(0, 0, 0, 0.08);
      background-color: $c-white;
      text-align: center;
      padding: 0.75rem;
      font-size: 14px;
      height: 100%;
      flex-direction: column;
      display: flex;
      justify-content: center;

      &:hover,
      &--active {
        cursor: pointer;
        box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.5);
        border: solid 1px $c-comprasion-selected-type-color;
      }
    }
  }

  .rating-dollar {
    &__active {
      svg {
        fill: $c-secondary;
      }
    }

    &__inactive {
      svg {
        fill: $c-grey-lightest;
      }
    }
  }

  .rating-leaf {
    &__active {
      svg {
        fill: #4d9900;
      }
    }

    &__inactive {
      svg {
        fill: $c-grey-lightest;
      }
    }
  }
}
