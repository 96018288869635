@import 'variables';
@import 'mixin.bootstrap';

@mixin tab-button {
  .tab-button {
    $c: &;
    @include unselectable;
    display: flex;
    margin: 0 0.5rem 0.5rem 0;
    color: inherit;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 0.75rem;
    border-radius: 4px;
    background: $c-tab-button-bg;

    @include media-breakpoint-up(md) {
      border-color: $c-tab-button-left-border;
      border-left-width: 0.375rem;
      border-left-style: solid;
    }

    @include media-breakpoint-down(sm) {
      border-color: $c-white;
      border-top-width: 0.375rem;
      border-top-style: solid;
      margin: 0 0 0.5rem 0;
    }

    &--active,
    &:hover {
      background-color: $c-tab-button-active-bg;
      border-color: $c-tab-button-active;
      color: inherit;

      #{$c}__icon svg {
        fill: $c-tab-button-active;
      }

      #{$c}__icon-image {
        border: 1px solid $c-tab-button-active;
      }
    }

    &:hover {
      @include interactive;
    }

    &--active {
      margin: 0 0 0.5rem;
      padding: 1rem 1.5rem 1rem 0.75rem;

      @include media-breakpoint-up(md) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }

    &__icon-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      width: 5rem;
      min-width: 5rem;
      height: 5rem;
      border-radius: 2px;
      overflow: hidden;
    }

    &__icon-sub {
      width: 60%;
      margin-top: 0.5rem;
    }

    &__icon-icon {
      width: 100%;
      height: 100%;

      .icon__72,
      .icon__72 svg {
        width: 100%;
        height: 100%;
      }

      padding: 0.5rem;
      display: block;
    }

    &__icon-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      border: 1px solid $c-tab-button;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: bold;
      color: $c-tab-button;
    }

    &__caption {
      text-align: left;
      padding-left: 1rem;
      font-size: 14px;
      line-height: normal;

      h3 {
        margin: 0;
      }

      p {
        margin: 0.25rem 0 0 0;
      }

      p:last-child {
        color: $c-tab-button;
      }
    }

    &__recommended {
      p:last-child {
        color: $c-tab-button-active;
      }
    }
  }
}
