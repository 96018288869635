/* You can add global styles to this file, and also import other style files */
@import '../../../libs/shared/styles/variables';
@import '../../../libs/shared/styles/mixins';

$container-max-widths: (
  sm: 540px,
  md: 720px,
  // add more width for lg because of offer-preview-heatpump-type-radiobutton component
  // https://theia-helion.atlassian.net/browse/HP-3748
  lg: 1085px,
  xl: 1140px,
);

$c-custom-control-active: $c-helion-blue-light;
$c-pricing-table-total: $c-helion-blue-light;
$c-checkbox-active: $c-helion-blue;
$c-widget-box-em-text: $c-helion-blue-light;
$c-offer-preview-em-title: $c-helion-blue;
$c-offer-preview-em-tab-title: $c-helion-blue;

@import '../../../libs/shared/styles/font.proxima';
@import 'bootstrap/scss/bootstrap.scss';
@import 'swiper/css/swiper.min.css';
@import '../../../libs/shared/styles/font.gotham';
@import '../../../libs/shared/styles/body';

@include address-typehead;
@include app-progress-bar;
@include footer-floating;
@include header;
@include choice-button;
@include choice-button-person;
@include choice-button-switch;
@include drawable-map;
@include ngx-slider;
@include form;
@include comparison-large;
@include offer-preview-loading-spinner;
@include offer-preview-em;
@include offer-preview-ht;
@include offer-preview-pv;
@include offer-preview-switch;
@include tab-button;
@include counter;
@include icon;
@include loading-spinner;
@include modal-images;
@include pricing-table;
@include ratio-graph;
@include ratio-slider;
@include roof-3d;

body.partner-default {
  .header__logo img {
    height: 3rem;

    @include media-breakpoint-down(sm) {
      height: 2rem;
    }
  }
}

.apartment-count {
  &__form-section {
    padding: 2rem 0;
  }

  @include counter();
}

body.offerpreview-pv {
  // because .offerpreview-pv class is also used in offer-total page where bottom-box should be visible
  .bottom-box {
    display: none;
  }
}
