@import 'variables';
@import 'mixin.bootstrap';

@mixin header {
  .header {
    height: $headerbar-height;
    width: 100%;
    border-bottom: 1px solid $c-headerbar-border;
    background: $c-white;

    @include media-breakpoint-down(sm) {
      height: 3.75rem;
    }

    &__container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__left {
      width: 25%;
      display: flex;
      justify-content: flex-start;
      @include media-breakpoint-up(xl) {
        width: 33%;
      }
    }

    &__center {
      display: flex;
      justify-content: center;
      @include media-breakpoint-up(md) {
        width: 350px;
      }
      @include media-breakpoint-up(lg) {
        width: 510px;
      }
    }

    &__right {
      width: 25%;
      display: flex;
      justify-content: flex-end;
      @include media-breakpoint-up(xl) {
        width: 33%;
      }
    }

    &__logo {
      height: 38px;
      img {
        height: auto;
        width: auto;
        max-height: 100%;
      }
      @include media-breakpoint-down(sm) {
        height: 1.5rem;
      }
    }

    &__offer-preview-title {
      text-align: center;

      h1 {
        margin: 0;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__phone {
      color: $c-grey;
      display: flex;
      justify-self: end;
      align-items: center;
      line-height: 1rem;

      &--icon {
        margin-right: 0.5rem;
        //@TODO from ht and em
        svg {
          fill: $c-charcoal;
        }
        //@TODO from pv
        .icon svg {
          fill: $c-header-phone;
        }
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__hero__progress_wrapper {
      padding: 0.75rem 0;
    }

    &__progress {
      position: relative;
      width: 100%;
      @include media-breakpoint-down(sm) {
        padding: 0 2rem;
      }
    }

    &__language {
      .dropdown-menu {
        min-width: 5rem;
      }

      .btn.btn-primary-outline {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 12px;
        padding: 0.5rem 0.5rem;
        border: 1px solid $c-grey-lighter;
        color: $c-grey-light;
      }

      .dropdown-toggle:after {
        border-top: 0.25rem solid;
        border-right: 0.25rem solid transparent;
        border-bottom: 0;
        border-left: 0.25rem solid transparent;
        margin-left: 0.25rem;
        vertical-align: 0.1rem;
      }
    }

    &__callback-mobile {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &.hero-header {
      height: auto;

      .header__progress,
      .header__offer-preview-title {
        padding: 0.75rem 0;
      }

      .header__container {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .hero-image {
        width: 100%;
        object-fit: cover;
      }

      .app-progress-bar__sections {
        margin-top: 0.5rem;
      }
    }
  }
}
