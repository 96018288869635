/* Design System Custom - No Bootstrap Default */

$fonts: 'Gotham Thin' '../assets/fonts/gotham/thin/gotham-thin-webfont' 100 normal 'gothamthin',
  'Gotham Light' '../assets/fonts/gotham/light/gotham-light-webfont' 200 normal 'gothamlight',
  'Gotham Light Italic' '../assets/fonts/gotham/light/gotham-lightitalic-webfont' 200 italic
    'gothamlight_italic',
  'Gotham Book' '../assets/fonts/gotham/book/gotham-book-webfont' 300 normal 'gothambook',
  'Gotham Book Italic' '../assets/fonts/gotham/book/gotham-bookitalic-webfont' 300 italic
    'gothambook_italic',
  'Gotham Medium' '../assets/fonts/gotham/medium/gotham-medium-webfont' 500 normal 'gothammedium',
  'Gotham Medium Italic' '../assets/fonts/gotham/medium/gotham-mediumitalic-webfont' 500 italic
    'gothammedium_italic',
  'Gotham Bold' '../assets/fonts/gotham/bold/gotham-bold-webfont' 700 normal 'gothambold',
  'Gotham Bold Italic' '../assets/fonts/gotham/bold/gotham-bolditalic-webfont' 700 italic
    'gothambold_italic';
@each $font in $fonts {
  @font-face {
    font-family: nth($font, 1);
    src: url(nth($font, 2) + '.eot');
    src: url(nth($font, 2) + '.eot?#iefix') format('embedded-opentype'),
      url(nth($font, 2) + '.woff2') format('woff2'), url(nth($font, 2) + '.woff') format('woff'),
      url(nth($font, 2) + '.ttf') format('truetype'),
      url(nth($font, 2) + '.svg#' + nth($font, 5)) format('svg');
    font-weight: nth($font, 3);
    font-style: nth($font, 4);
    font-display: swap;
  }
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham OTF/Gotham-Bold.woff2') format('woff2'),
    url('../assets/fonts/Gotham OTF/Gotham-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham OTF/Gotham-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/Gotham OTF/Gotham-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham OTF/Gotham-Book.woff2') format('woff2'),
    url('../assets/fonts/Gotham OTF/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham OTF/Gotham-BookItalic.woff2') format('woff2'),
    url('../assets/fonts/Gotham OTF/Gotham-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham OTF/Gotham-LightItalic.woff2') format('woff2'),
    url('../assets/fonts/Gotham OTF/Gotham-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham OTF/Gotham-MediumItalic.woff2') format('woff2'),
    url('../assets/fonts/Gotham OTF/Gotham-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham OTF/Gotham-Medium.woff2') format('woff2'),
    url('../assets/fonts/Gotham OTF/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham OTF/Gotham-Light.woff2') format('woff2'),
    url('../assets/fonts/Gotham OTF/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
