@import 'variables';
@import 'mixin.bootstrap';
@import 'mixin.interactive';

@mixin choice-button-shared-hover-active {
  border-color: $c-choice-button-hover;
  background: $c-choice-button-bg-hover;
  color: $c-choice-button-text-hover;

  .choice-button__icon-image {
    color: $c-choice-button-icon-hover;
    background-color: $c-choice-button-bg-hover;
  }
}

@mixin choice-button {
  .choice-button {
    $c: &;
    @include unselectable;
    display: flex;
    width: 100%;
    min-height: 90px;
    margin: 0 0 0.5rem;
    border-color: $c-choice-button;
    color: $c-choice-button-text;
    align-items: center;
    padding: 0.75rem 1.5rem 0.75rem 0.5rem;
    border-radius: 3px;
    background: $c-choice-button-bg;
    font-weight: normal;
    line-height: normal;

    /* The container of the icon/image should not shrink and maintain its size also if there is not enough space. If not, the icons/images are squeezed together. */
    .choice-button__icon {
      flex-shrink: 0;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }

    #{$c}__arrow svg,
    #{$c}__icon svg {
      fill: $c-choice-button;
      color: $c-choice-button;
    }

    /* Only enable hover styles if the device supports hover and there is a fine pointer */
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        @include choice-button-shared-hover-active;
        #{$c}__arrow svg,
        #{$c}__icon svg {
          fill: $c-choice-button-icon-hover;
          color: $c-choice-button-icon-hover;
        }
      }
    }

    /* Enable active styles for every device, regardless of hover and pointer support */
    &--active {
      @include choice-button-shared-hover-active;
      #{$c}__arrow svg,
      #{$c}__icon svg {
        fill: $c-choice-button-icon-hover;
        color: $c-choice-button-icon-hover;
      }
    }

    &__icon {
      width: 4rem;
      height: 4rem;
      flex-grow: 0;
      border-radius: 2px;
      overflow: hidden;
    }

    &__icon-icon {
      width: 100%;
      height: 100%;
      .icon__72,
      .icon__72 svg {
        width: 100%;
        height: 100%;
      }
      padding: 0.5rem;
      display: block;
    }

    &__icon-image {
      width: 100%;
      height: 100%;
      /* We use rgba here instead of transparentize because the value for $c-choice-button-bg could come from a css variable */
      background: center center/contain no-repeat rgba($c-choice-button-bg, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: bold;
      color: $c-choice-button;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &__caption {
      flex-grow: 1;
      text-align: left;
      padding-left: 2rem;
      @include media-breakpoint-down(sm) {
        padding-left: 1rem;
      }
      > div:last-child {
        font-size: 14px;
      }
    }

    &__arrow {
      flex-grow: 0;
    }
  }
}

@mixin choice-button-person {
  .choice-button-person {
    $c: &;
    @include unselectable;
    display: flex;
    width: 100%;
    margin: 0 0 0.5rem;
    border-color: $c-choice-button;
    color: $c-choice-button-text;
    align-items: center;
    padding: 1rem 1.5rem;
    border-radius: 3px;
    background: $c-choice-button-bg;
    font-weight: normal;
    line-height: normal;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }

    #{$c}__arrow svg,
    #{$c}__icon svg {
      fill: $c-choice-button;
    }

    /* Only enable hover styles if the device supports hover and there is a fine pointer */
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        @include choice-button-shared-hover-active;
        #{$c}__arrow svg,
        #{$c}__icon svg {
          fill: $c-choice-button-icon-hover;
          color: $c-choice-button-icon-hover;
        }
      }
    }

    /* Enable active styles for every device, regardless of hover and pointer support */
    &--active {
      @include choice-button-shared-hover-active;
      #{$c}__arrow svg,
      #{$c}__icon svg {
        fill: $c-choice-button-icon-hover;
        color: $c-choice-button-icon-hover;
      }
    }

    &__icon {
      display: flex;
      flex-grow: 0;
      justify-content: flex-end;
      width: 80px; // based on a maximum of 5 persons displayed
    }

    &__icon-icon {
      margin-left: -1rem;
    }

    &__caption {
      flex-grow: 1;
      text-align: left;
      padding-left: 2rem;
      @include media-breakpoint-down(sm) {
        padding-left: 1rem;
      }
    }

    &__arrow {
      flex-grow: 0;
    }
  }
}
@mixin choice-button-switch {
  .choice-button-switch {
    $c: &;
    @include unselectable;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem 1.5rem;
    border-radius: 3px;
    background: $c-white;
    font-weight: normal;
    line-height: normal;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }

    &--active {
      #{$c}__icon {
        background: $c-choice-button-bg-hover;

        svg {
          color: $c-choice-button-icon-hover;
          fill: $c-choice-button-icon-hover;
        }
      }
    }

    &__icon {
      width: 3rem;
      height: 3rem;
      padding: 0.25rem;
      background: transparentize($c-helion-blue, 0.9);
      flex-grow: 0;
      border-radius: $border-radius;

      &:hover {
        cursor: pointer;
      }

      app-icon .icon__72,
      app-icon .icon__72 svg {
        width: 100%;
        height: 100%;
      }
    }

    &__caption {
      flex-grow: 1;
      text-align: left;
      padding-left: 2rem;

      &:hover {
        cursor: pointer;
      }
    }

    &__arrow {
      flex-grow: 0;
    }
  }
}
