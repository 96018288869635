@import 'variables';
@import 'mixin.bootstrap';
@import 'mixin.rangeslider';

@mixin ratio-slider {
  .ratio-slider {
    padding: 1rem;
    margin-bottom: 9rem;

    &__label {
      font-size: 12px;
      font-weight: 400px;
      margin-left: -30px;
    }

    &__input-control {
      width: 80px;
      border: 1px solid rgba(0, 0, 0, 0.125);
      outline-color: rgba(0, 0, 0, 0.125);
      text-align: right;
      font-size: 14px;
      margin: 0 10px;
    }

    &__input-unit {
      font-size: 12px;
    }

    &__slider-wrapper {
      position: relative;
      margin: 0 1rem;
      display: flex;
      align-content: center;
    }

    &__slider-label-right,
    &__slider-label-left {
      font-size: 12px;
      flex-grow: 0;
      max-width: 14%;
      display: flex;
      align-items: center;
      padding-top: 1rem;
      line-height: normal;
      @include media-breakpoint-down(sm) {
        max-width: 19%;
      }
    }

    &__slider-label-right {
      padding-left: 1rem;
      text-align: right;
    }

    &__slider-label-left {
      padding-right: 1rem;
    }

    &__slider {
      flex-grow: 1;
      position: relative;
      @include ngx-slider;
      /*consumption selector only*/
      .ngx-slider {
        margin: 4rem 0 1rem;
      }
      /*consumption selector only*/
    }

    &__slider-legend-wrapper {
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
    }

    &__slider-legend-bubble {
      position: absolute;
      box-sizing: border-box;
      left: 50%;
      bottom: -10rem;
      font-size: 14px;
      color: $c-grey-light;
      text-align: center;
      line-height: normal;
      transform: translateX(-50%);
      user-select: none;
      width: 292px;

      &:hover {
        cursor: pointer;
      }
    }

    &__slider-legend-arrow {
      position: absolute;
      left: 0;
      bottom: -4rem;
      width: 30px;
      transform: translateX(-50%);
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }

    &__slider-best-fit-wrapper {
      position: absolute;
      left: 15px;
      top: 0;
      right: 15px;
      bottom: 0;
    }

    &__slider-best-fit-bubble {
      position: absolute;
      box-sizing: border-box;
      left: 0;
      bottom: -0.5rem;
      background: $c-ratio-slider;
      color: $c-white;
      font-size: 14px;
      border-radius: 2px;
      padding: 0.25rem 0.5rem;
      white-space: nowrap;
      line-height: normal;
      transform: translateX(-50%);
      user-select: none;

      &:hover {
        cursor: pointer;
      }
    }

    &__slider-best-fit-tick {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 2rem;
      background: $c-ratio-slider;
      width: 2px;
      transform: translateX(-50%);
    }
  }
}
