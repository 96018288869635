@import 'variables';
@import 'mixin.bootstrap';

$dot: 14px;
$line: 2px;

@mixin app-progress-bar {
  .app-progress-bar {
    color: $c-grey;
    width: 100%;
    // min-width: 23rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__bar-container {
      position: relative;
      width: 66%;
      padding: 0 3rem;
      margin: 1rem 0;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    &__bar {
      width: 100%;
      position: absolute;
      top: -$line/2;
      left: 0;
      height: $line;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        height: $line;
      }
    }

    &__bar-inactive {
      width: 100%;
      z-index: 2;
      background: $c-progress-bar-inactive;
    }

    &__bar-active {
      z-index: 3;
      width: 0;
      background: $c-progress-bar-active;
      transition: width 0.5s ease-out;
    }

    &__milestone {
      z-index: 4;
      position: absolute;
      top: -$dot / 2;
      border-radius: $dot/2;
      margin-left: -$dot/2;
      background: $c-progress-bar-inactive;
      width: $dot;
      height: $dot;

      &-left {
        left: 0;
      }

      &-center {
        left: 50%;
      }

      &-right {
        left: 100%;
      }

      &--active {
        background: $c-progress-bar-active;
      }
    }

    &__sections {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      line-height: 14px;

      > div {
        width: 33.333%;
        text-align: center;
        white-space: nowrap;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
