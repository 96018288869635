@import 'variables';

@mixin counter {
  .count {
    &__counter {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    &__counter-button {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      margin: 1rem;
      border-radius: 50%;
      user-select: none;
      text-align: center;
      font-size: $font-size-large;
      color: $c-helion-blue-light;
      border: 1px solid $c-helion-blue-light;
      &:hover {
        cursor: pointer;
      }
    }
    &__counter-input {
      max-width: 4rem;
      input {
        padding: 0;
        text-align: center;
        font-size: $font-size-large;
      }
    }
  }
}
