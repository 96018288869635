@import 'variables';

@mixin offer-preview-switch {
  .offer-preview-switch {
    $c: &;
    @include unselectable;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 0 1rem 0;
    border-radius: 3px;
    font-weight: normal;

    &--active {
      #{$c}__icon svg {
        fill: $c-ui-switch-active;
        color: $c-ui-switch-active;
      }
    }

    &--active {
      #{$c}__icon {
        background: $c-offer-preview-switch-active-bg;
      }
    }

    &__icon {
      width: 3rem;
      height: 3rem;
      padding: 0.25rem;
      background: transparentize($c-ui-switch, 0.9);
      min-width: 3rem;
      border-radius: 2px;
      overflow: hidden;

      &:hover {
        cursor: pointer;
      }

      app-icon .icon__72,
      app-icon .icon__72 svg {
        width: 100%;
        height: 100%;
      }
    }

    &__caption {
      flex-grow: 1;
      text-align: left;
      padding-left: 1rem;
      display: flex;
      align-content: flex-start;

      &:hover {
        cursor: pointer;
      }

      > div {
        p {
          margin: 0;
          font-size: 14px;
          line-height: normal;
        }

        p:first-child {
          font-weight: bold;
        }

        p:last-child {
          font-weight: 300;
        }
      }
    }

    &__edit {
      padding: 0 1rem 0.5rem 0;
      font-size: 14px;
    }

    &__arrow {
      flex-grow: 0;
    }

    &__tooltip {
      @media (max-width: $breakpoint-small) {
        display: none;
      }

      img {
        display: inline-block;
        vertical-align: 0;
        margin: 0 0.5rem;
      }
    }
  }
}
