@import 'variables';
@import 'mixin.bootstrap';
@import 'mixin.interactive';

@mixin footer-floating {
  .footer-floating {
    color: $c-footer;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $navbar-mobile-height;
    background: $c-footer-bg;

    svg {
      fill: $c-footer-arrow;
    }

    &__inner {
      padding: 0.25rem;
      display: flex;
      justify-content: space-between;

      &_h_70 {
        height: 70%;
      }

      &_h_100 {
        height: 100%;
      }
    }

    &__left {
      //@include media-breakpoint-up(xl) {
      //  flex: 1;
      //}

      @include media-breakpoint-down(xl) {
        padding: 0 0.25rem;
      }
    }

    &__right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 100%;

      & > app-button {
        margin: 0 0 0 1rem;
      }

      .btn-outline-primary {
        background: $c-white;

        &:hover:not(:disabled) {
          color: $c-helion-blue;
          background: lighten($c-helion-blue, 66);
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 0 0.75rem;
        app-button,
        .btn {
          margin: 0;
          width: 100%;
        }
      }

      @media (max-width: 410px) {
        padding: 0 0.25rem;
        .btn.btn-secondary {
          font-size: 16px;
          padding: 0.1rem 0.25rem;
          min-height: 48px;
        }
      }
    }

    &__arrow {
      text-align: center;
      margin: 8% 0;

      svg {
        fill: $c-helion-orange;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .bounce {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }

    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateX(0);
      }
      40% {
        transform: translateX(-30px);
      }
      60% {
        transform: translateX(-15px);
      }
    }

    &__links {
      background-color: $c-black;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30%;
    }

    // must be stronger than `body.partner-id a` (12) selector
    // as a result here `.footer-floating .footer-floating__link` (20) instead of `.footer-floating__link` (10)
    & &__link {
      font-size: 0.7rem;
      color: $c-white;
      text-decoration: none;

      &:hover {
        color: $c-grey-light;
      }

      &:not(:first-child) {
        margin-left: 2rem;
      }
    }

    .share-button {
      cursor: pointer;
      margin-left: 1rem;

      svg {
        fill: $c-footer-share-button;
      }
    }

    @media screen and (min-width: 960px) {
      height: $navbar-height;
    }

    @media screen and (max-width: 959px) {
      &.footer-floating_with-links {
        .btn {
          padding: 0.35rem 0.85rem;
          font-size: 15px;
        }

        .btn.btn-secondary {
          min-height: 32px;
        }

        app-icon .icon__32,
        app-icon .icon__32 svg {
          width: 1.5rem;
          height: 1.5rem;
          line-height: 1.3;
        }
      }
    }
  }

  .back-arrow {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      color: #fff;
    }

    &__link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $c-snow;
      text-decoration: none;
      @include clickable-inverted;
    }

    &__text {
      margin-left: 0.5rem;
    }
  }
}
