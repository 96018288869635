@import 'variables';

@mixin modal-images {
  .modal-images {
    &:hover {
      cursor: pointer;
    }

    &__modal {
      max-width: 750px;

      .swiper-wrapper {
        display: flex;
        align-items: baseline;
      }

      .swiper-slide {
        text-align: center;
        padding: 0 0 2rem 0;
      }

      .swiper-button-next,
      .swiper-button-prev {
        background-color: transparent;
        background-size: 77% 77%;
        padding: 0.25rem;
        width: 2rem;
        height: 2rem;
        border-radius: 3px;
      }

      .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
      }
    }

    &__modal-body {
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 70vh;
      }
    }
  }
}
