@import 'variables';
@import 'mixin.form';
@import 'mixin.style';
@import 'bootstrap/scss/mixins/_caret.scss';

$caret-width: 0.5em;
$caret-vertical-align: 0;

@mixin address-typehead {
  app-address-typeahead {
    width: 100%;
  }

  .address-typeahead {
    width: 100%;
    position: relative;
    z-index: 3;

    &__select {
      width: 100%;
      background: #fff;
      border: 1px $c-address-typeahead-active-bg solid;
      display: flex;
      position: absolute;
      left: 0;
      flex-wrap: wrap;
      flex-direction: column;
      box-sizing: border-box;
    }

    &__select-item {
      font-family: $font-family-regular;
      font-size: $font-size-normal;
      color: $c-charcoal;
      width: 100%;
      padding: 0.3rem 0.5rem;
      box-sizing: border-box;

      &--selected,
      &:hover {
        background: $c-address-typeahead-active-bg;
        color: #fff;
      }

      z-index: 3;
    }

    &__search-string {
      font-family: $font-family-semibold;
      font-weight: 600;
    }

    @include form;
  }

  .address-lookup {
    min-height: 75vh;
    .list-box {
      &.info {
        padding: 2rem 1rem;
        background-color: $c-white;
        border-radius: 1rem;
      }
      .info-title {
        color: $c-helion-blue-light;
      }
      img {
        margin: 1rem 0;
      }
      &__content {
        @include div-list-bullets;
      }
    }

    .privacy-link {
      display: inline-block;
      margin-left: 0.25rem;
    }

    .address-lookup-panel {
      &.panel.card,
      .card-header {
        background-color: $c-white;
      }
      .btn.btn-link {
        color: $c-helion-blue-light;
      }

      .panel-heading .accordion-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &[aria-expanded='false'] {
          @include caret(down);
        }

        &[aria-expanded='true'] {
          @include caret(up);
        }

        &::after,
        &::before {
          color: $c-helion-blue-light;
        }
      }
    }

    &__subtitle {
      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
}
