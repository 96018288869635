@import '../styles/variables.scss';
@import '../styles/mixins.scss';

html,
body {
  height: 100%;
  width: 100%;
}

body {
  background: $c-snow;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.digital-upload {
    font-weight: 400;
    --font-family-du: Gotham, Arial, Sans-Serif;
    --font-color-du: #171717;
    --title-du-clor: #00a5e4;
    --title-du-font-size: 4rem;
    --title-du-margin: 6rem 0;
    --custom-radio-button-active-color-cms: #00cdff;
    --custom-radio-button-border-color-cms: #00cdff;
    font-family: var(--font-family-du);
    color: var(--font-color-du);
    background: $c-white;

    p {
      font-weight: 400;
    }

    b,
    strong {
      font-weight: 700;
    }

    input {
      cursor: pointer;
    }

    input,
    select,
    textarea {
      -webkit-user-select: text;
    }

    .greeting {
      h1 {
        margin: var(--title-du-margin);
        color: var(--title-du-clor);
        font-size: var(--title-du-font-size);
        text-align: center;
        font-weight: 700;
      }
    }

    .radio-buttons > label {
      font-size: 1rem;
    }

    .custom-control.custom-radio .custom-control-label:before {
      width: 26px;
      height: 26px;
    }

    .custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
      left: 7px;
      top: 11px;
      width: 12px;
      height: 12px;
    }

    .btn {
      font-size: 1rem;
      font-weight: 400;
      border: 0 solid;
      border-radius: 7px;
      color: $c-white;
      padding: 8px 32px;

      app-icon .icon {
        color: $c-white;
      }

      &.btn-primary {
        background: #00cdff;

        &:active,
        &:hover {
          background-color: #003250;
        }
      }

      &.btn-outline-primary {
        background: transparent;
        border: 2px solid #00cdff;
        color: #00cdff;

        &:active,
        &:hover {
          color: $c-white;
          background: #00cdff;
          border-color: #00cdff;
        }
      }
    }
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  body {
    cursor: pointer;
  }
}

// global bootstrap overrides

a {
  cursor: pointer;
  text-decoration: underline;
}

img {
  max-width: 100%;
  height: auto;
}

.btn {
  font-size: 18px;
  font-weight: 600;
  padding: 0.5rem 1.25rem;

  &.btn-link {
    padding: 0.5rem 0;
  }
}

.btn-outline-primary {
  background: $c-white;
}

.btn-secondary {
  color: $c-white;
}

input.form-control,
select.form-control {
  color: $c-black;
  height: calc(2.75rem + 2px);
  font-size: 14px;
}

input.form-control:disabled {
  border-color: $c-grey;
  background: $c-grey-lightest;
  color: $c-black;
  opacity: 1;
}

small,
.small {
  font-size: 14px;
}

.with-shadow {
  box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.5);
}

.justify-content-evenly {
  justify-content: space-evenly;
}

p {
  font-weight: 300;
}

h1 {
  color: $c-title;
  margin-bottom: 1.5rem;
  font-weight: 300;
  font-size: 28px;
}

h3 {
  font-size: 18px;
  margin-bottom: 0.75rem;
  color: $c-title;
}

.tooltip {
  .tooltip-inner {
    background: $c-helion-blue-light;
    color: $c-white;
    line-height: 1.33;
    font-size: 12px;
  }

  .tooltip-arrow:before,
  .tooltip-arrow {
    border-top-color: $c-helion-blue-light;
    border-bottom-color: $c-helion-blue-light;
  }
}

.tooltip.show {
  opacity: 1 !important;
}

.modal-header {
  background: $c-modal-header;
  font-size: 18px;
  font-weight: bold;
  color: $c-white;

  button span {
    color: $c-white;
  }
}
.modal-footer {
  background: $c-grey-lightest;
}

// ngx-switch / toggle override

ui-switch {
  .switch {
    border: 1px solid $c-ui-switch !important;
  }
  .switch small {
    border: 1px solid $c-ui-switch !important;
    box-sizing: content-box !important;
    top: -1px !important;
    left: -1px !important;
  }
  .switch.checked {
    border: 1px solid $c-ui-switch-active-border !important;
    background: $c-ui-switch-active-bg !important;
  }
  .switch.checked small {
    border: 1px solid $c-ui-switch-active !important;
    left: 20px !important;
  }
}

// basic breakpoint handling for global elements

@include media-breakpoint-up(md) {
  h1 {
    font-size: 1.5rem;
  }
}

@include media-breakpoint-up(lg) {
  h1 {
    font-size: 1.75rem;
  }
}

.alert-container {
  position: fixed;
  bottom: $navbar-mobile-height;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  @media screen and (min-width: 960px) {
    bottom: $navbar-height;
  }
}

#plugin-viewer-shared-offerpreview-modal {
  width: 100%;
  padding: 10px;

  canvas {
    display: block;
  }
}

#plugin-viewer-shared-offerpreview {
  width: 522px;
  position: absolute;
  left: -99999999999999px;

  canvas {
    display: block;
  }
}

app-step-wrapper {
  @keyframes stepAnimation {
    0% {
      opacity: 0;
      transform: translateX(20%);
    }
    33% {
      opacity: 0.33;
      transform: translateX(0);
    }
    66% {
      opacity: 1;
    }
  }

  animation: stepAnimation 0.6s ease-out forwards;

  display: block;
  position: relative;
}

app-step-columns {
  display: block;
  padding: 2rem 0 $navbar-height;
  //margin: 0 0 2rem 0;
}

app-button {
  .btn {
    gap: 0.5rem;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

body.total,
body.offerpreview,
body.offerpreviewpvx,
body.offerpreview-em,
body.offerpreview-ht,
body.offerpreview-pv {
  &:not(.version-2) .footer-floating__right {
    @include media-breakpoint-down(sm) {
      flex-grow: 1;
      app-button:first-of-type:not(:only-of-type) {
        display: none;
      }
    }
  }

  &.version-2 .footer-floating__right {
    @include media-breakpoint-down(sm) {
      app-button {
        overflow: hidden;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .footer-floating__left {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &.modal-open app-root {
    filter: blur(4px);
  }
}

.IE.total,
.IE.offerpreview,
.IE.offerpreviewpvx,
.IE.offerpreview-em,
.IE.offerpreview-ht,
.IE.offerpreview-pv {
  .widget-box > div:last-child > div {
    max-width: 100%;
  }
}

.bidirectional::after {
  content: '';
  width: 1rem;
  display: inline-block;
  height: 1rem;
  margin-left: 0.5rem;
  background-color: $c-secondary;
  vertical-align: middle;
  mask-image: url('../assets/icons/var/bidirectional.svg');
}

a.btn {
  text-decoration: none;
}

.ht-technologies-hybrid-modal {
  max-height: 94vh;
  width: 1040px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;

  .comparison-settings__sliders > div {
    padding: 0;
  }
}

@include icon;
@include loading-spinner;
@include button-reset;
