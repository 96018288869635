@import 'variables';

@mixin ratio-graph {
  .ratio-graph {
    width: 100%;

    &__graph-container {
      background: $c-grey-lightest;
    }

    &__graph {
      padding: 1.5rem 0 0;
      position: relative;
    }

    &__bars {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
      position: relative;
    }

    &__left-bar,
    &__right-bar {
      position: relative;
      margin: 0 5%;
      height: 50%;
      width: 40%;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
      color: $c-white;
      padding: 0 0.5rem 0.5rem;
      text-align: center;
      line-height: normal;

      &._transparent {
        color: $c-ratio-graph-transparent-text;
        background: transparent;
      }
    }

    &__left-bar {
      background: $c-ratio-graph-left-bar;
    }

    &__right-bar {
      background: $c-ratio-graph-right-bar;
    }

    &__horizontal-line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 90%;
      height: 100%;
      margin: 0 5%;
      border-top: 2px dashed $c-ratio-graph-horizontal-line;
    }

    &__legend {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
      padding: 0.5rem 0 0;
      line-height: normal;

      > div {
        margin: 0 5%;
        width: 40%;
      }
    }
  }
}
