@import 'variables';

@mixin pricing-table {
  .pricing-table {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;

    p {
      margin: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      color: $c-black;
      border-bottom: 1px solid $c-pricing-table-border;
      margin: 0;
    }

    &__section {
      margin: 0 0 1.25rem 0;

      &:last-child {
        margin: 0;
      }

      &--options {
        > .row:first-child > .col:last-child {
          text-align: left;
        }

        > .row > .col:last-child {
          text-align: right;
        }

        // pv only @TODO check if it broke something
        .app-icon .icon__24,
        app-icon .icon__24 svg {
          width: 0.8rem;
          fill: grey;
        }
        // pv only
      }

      &--total {
        font-weight: bold;
        border-bottom: 1px solid $c-pricing-table-total;

        > div > div:last-child {
          text-align: right;
        }
      }

      &--underline {
        border-top: 1px solid $c-pricing-table-border;
      }

      &--investment {
        border-bottom: 1px solid $c-pricing-table-border;
        border-top: 1px solid $c-pricing-table-border;

        > div:last-child {
          color: $c-pricing-table-total;
          font-weight: bold;
        }

        > div > div:last-child {
          text-align: right;
        }
      }

      &--federal-grant,
      &--taxes,
      &--tax-deduction,
      &--subsidies,
      &--system {
        > div > div:last-child {
          text-align: right;
        }
      }
    }
  }
}
