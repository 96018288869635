@import 'variables';

@mixin drawable-map {
  .drawable-map {
    position: relative;

    .address-typeahead .app-input label {
      color: $c-white;
    }

    .form-text.text-muted {
      display: none;
    }

    &__search {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.75rem 1rem 0;
      width: 100%;

      .app-input label {
        color: $c-white;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0 0.5rem;
      }
    }

    &__search-input {
      width: 100%;
    }

    &__controls {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 1rem;

      .btn {
        background: $c-white;
        padding: 0.25rem;
        margin: 0 0 0 0.25rem;
      }
    }

    #googleMap {
      width: 100%;
      height: 470px;

      & * {
        cursor: pointer;
      }
    }
  }
}
