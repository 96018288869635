@import 'variables';

@mixin ngx-slider {
  .custom-slider.ngx-slider {
    margin: 4rem 0 3rem;

    .ngx-slider-bar {
      background: $c-grey-lighter;
      height: 3px;
    }

    .ngx-slider-selection {
      background: $c-ngx-slider-active;
    }

    .ngx-slider-pointer,
    .ngx-slider-active {
      background: $c-white;
      border: 2px solid $c-ngx-slider-active;

      &:after {
        background: transparent !important;
      }

      &:focus {
        outline: 0;
      }

      &:hover {
        background: lighten($c-ngx-slider-active, 33);
      }
    }

    .ngx-slider-tick.ngx-slider-selected {
      background: $c-ngx-slider-active;
    }

    .ngx-slider-bubble {
      font-size: 14px;
    }

    .ngx-slider-model-value {
      background: $c-ngx-slider-active;
      color: $c-white;
      border-radius: 2px;
      bottom: 1.75rem;
      padding: 0.25rem 0.5rem;
    }

    .ngx-slider-bubble.ngx-slider-limit {
      top: 1.5rem;
      bottom: 0;
    }
  }
}
