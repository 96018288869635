$fonts: '../assets/fonts/proxima';

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fonts}/1132144/93cc6d34-798e-42c8-87d2-fd6391801b63.eot?#iefix');
  src: url('#{$fonts}/1132144/93cc6d34-798e-42c8-87d2-fd6391801b63.eot?#iefix') format('eot'),
    url('#{$fonts}/1132144/d9fe41ee-4904-4a11-ba11-b61cd3be767f.woff2') format('woff2'),
    url('#{$fonts}/1132144/2fa30669-9bbd-4ced-912f-db94a367ed6c.woff') format('woff'),
    url('#{$fonts}/1132144/c12b3ebb-3771-483f-a84a-ac63d615c28e.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fonts}/1539042/4d54327f-d317-4d88-bdac-1edbd6f9b677.eot?#iefix');
  src: url('#{$fonts}/1539042/4d54327f-d317-4d88-bdac-1edbd6f9b677.eot?#iefix') format('eot'),
    url('#{$fonts}/1539042/1bfff5fb-2540-4d1d-9f13-0afec7ba3c41.woff2') format('woff2'),
    url('#{$fonts}/1539042/be2bfc33-e5c3-4b44-9c4d-3f1249e26a5a.woff') format('woff'),
    url('#{$fonts}/1539042/a46943c3-227c-44d3-aa50-e5f87f7cec12.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fonts}/1529351/b918fc25-caba-4cf5-8606-d77742e476f4.eot?#iefix');
  src: url('#{$fonts}/1529351/b918fc25-caba-4cf5-8606-d77742e476f4.eot?#iefix') format('eot'),
    url('#{$fonts}/1529351/e898042a-5eb9-4810-b578-c3993ab58f0f.woff2') format('woff2'),
    url('#{$fonts}/1529351/cb9d4f02-d326-46b0-a857-930cce057f80.woff') format('woff'),
    url('#{$fonts}/1529351/75cafc03-22e4-41b0-8bc2-1b9081506ecc.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fonts}/1132171/87e4b4fc-cdf1-450a-8bed-dd818cba908d.eot?#iefix');
  src: url('#{$fonts}/1132171/87e4b4fc-cdf1-450a-8bed-dd818cba908d.eot?#iefix') format('eot'),
    url('#{$fonts}/1132171/ae47a7f5-89da-4879-b934-29722c3dd451.woff2') format('woff2'),
    url('#{$fonts}/1132171/70ae52ec-d89b-4c6a-9402-854ebe423c54.woff') format('woff'),
    url('#{$fonts}/1132171/6a35571c-ea14-4dac-9ae1-0e7af0abeec8.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fonts}/1539032/7fbce033-e997-47ad-885e-dff5c157f4b6.eot?#iefix');
  src: url('#{$fonts}/1539032/7fbce033-e997-47ad-885e-dff5c157f4b6.eot?#iefix') format('eot'),
    url('#{$fonts}/1539032/101e9e4e-32d6-418e-a770-3f37ae910c72.woff2') format('woff2'),
    url('#{$fonts}/1539032/14f0515c-3586-450f-b1d1-b597aa8a104d.woff') format('woff'),
    url('#{$fonts}/1539032/9ad334ea-1614-418d-ae42-bba67cb79274.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fonts}/1540393/eaffcc3a-8b48-4e09-b09d-419c1ddf4571.eot?#iefix');
  src: url('#{$fonts}/1540393/eaffcc3a-8b48-4e09-b09d-419c1ddf4571.eot?#iefix') format('eot'),
    url('#{$fonts}/1540393/ced073da-c451-4dea-99e1-6ebffd7a02c1.woff2') format('woff2'),
    url('#{$fonts}/1540393/6f12632d-c10c-4b34-9ae8-50ab8eb604e5.woff') format('woff'),
    url('#{$fonts}/1540393/2e549e47-584f-442c-b25c-59c6619ed0db.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
