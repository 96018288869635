@import 'variables';
@import 'mixin.bootstrap';
@import 'mixin.style';
@import 'mixin.pricing-table';
@import 'mixin.widget-box';

$byes-blue: #0093bb;
$c-offer-preview-em-title: black !default;
$c-offer-preview-em-tab-title: #4a4a4a !default;

@mixin offer-preview-em {
  .offer-preview-em {
    align-items: stretch;
    background: $c-white;

    &__sidebar {
      background: $c-offer-preview-sidebar-bg;
      padding: 1rem 0 1rem 0.5rem;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__sidebar-note {
      text-align: center;
      margin-top: 24px;
      color: $c-grey-light;

      small {
        font-size: 14px;
      }
    }

    &__fake-bg {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      left: -100%;
      background: $c-offer-preview-sidebar-bg;
    }

    &__topbar {
      background: $c-offer-preview-sidebar-bg;
      padding: 0;

      .swiper-slide {
        padding: 1rem 1.5rem 0;
      }

      .swiper-button-next,
      .swiper-button-prev {
        transform: scale(0.4);
      }

      .swiper-button-next {
        right: 0;
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &__main {
      padding: 0.75rem 0.75rem (2 * $navbar-height);
      background: $c-white;

      > div .center {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
          margin-bottom: 2.5rem;
        }
      }

      @include media-breakpoint-up(md) {
        padding: 1rem 1.5rem (2 * $navbar-height);
      }

      .widget-icons {
        app-icon {
          .icon__32 {
            min-width: 52px;
            min-height: 52px;
          }
          div {
            margin: auto;
            margin-bottom: 0.5rem;
            svg {
              min-width: 52px;
              min-height: 52px;
              fill: $c-icon;
            }
          }
        }
      }

      @include pricing-table;
      @include widget-box;
      @include widget-box-em;
    }

    &__template {
      background: $c-grey-lightest;
      border-radius: $border-radius;
      margin-bottom: 2rem;
      margin-left: initial;
      margin-right: initial;

      & > div {
        padding: 0.75rem 1rem 0.75rem 0.75rem;
      }
    }

    &__template-image {
      position: relative;
      border-radius: 3px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__template-image-button {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $c-template-image-button;
      width: 42px;
      height: 42px;
      border-top-left-radius: 42px;
      padding: 12px 0 0 12px;

      svg {
        fill: $c-white;
      }
    }

    &__car-subsidy-disclaimer {
      p {
        font-size: 12px !important;
        margin-left: 6px;
        margin-top: 18px;
      }
    }

    &__template-description,
    &__info-box {
      padding: 0.75rem 1rem;
      @include div-list-bullets;

      h3 {
        color: $c-offer-preview-em-title;
      }
    }

    &__info-box {
      padding: 0;
      margin: 2rem 0;
    }

    &__widget-box-container {
      align-items: stretch;
    }

    .widget-box-container {
      @include media-breakpoint-down(sm) {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
    }

    .options {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .summary {
      &__preview-image img {
        width: 100%;
        height: auto;

        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        > div {
          width: 50%;
          margin: 0 0 0.66rem;
          padding: 0 0 0 1.5rem;
          position: relative;
          font-weight: 300;
          font-size: 14px;
        }

        > div:before {
          content: '›';
          display: block;
          position: absolute;
          top: -0.9rem;
          left: 0.5rem;
          width: 1rem;
          height: 1rem;
          border-radius: 0.5rem;
          color: $c-summary-list-item-arrow;
          font-size: 32px;
          font-weight: 500;
        }
      }
    }

    .bottom-box {
      &__image {
        img {
          width: 100%;
          height: auto;
        }
      }

      &__content {
        @include div-list-bullets;
      }
    }

    .buttons {
      .row:last-child {
        margin-top: 0.5rem;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      .row {
        margin: 0 0 1rem 0;
      }

      .btn {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    .tab-button {
      $c: &;
      //background: $c-tab-white-inactive;
      //@include media-breakpoint-up(md) {
      //  border-left-color: $c-tab-white-inactive;
      //}
      @include media-breakpoint-down(sm) {
        border-top-color: $c-tab-white-inactive;
      }
      &__icon {
        height: 7rem;
      }
      &__icon-image {
        border: none;
      }
      &--active,
      &:hover {
        //background: $c-white;
        //border-color: $byes-blue;
        .tab-button__icon svg {
          fill: $byes-blue;
        }
        .tab-button__icon-image {
          border: none;
        }
      }
      &__caption {
        h3 {
          color: $c-offer-preview-em-tab-title;
        }
      }
    }

    .widget-box {
      @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
      }
    }

    .offer-preview-dropdown {
      $c: &;
      @include unselectable;
      display: flex;
      width: 100%;
      align-items: center;
      padding: 2rem 0 2rem 0;
      border-radius: 3px;
      font-weight: normal;

      &--active {
        #{$c}__icon svg {
          color: $c-helion-orange;
          fill: $c-helion-orange;
        }
      }

      &--active {
        #{$c}__icon {
          background: transparentize($c-helion-orange, 0.9);
        }
      }

      &__icon {
        width: 3rem;
        height: 3rem;
        padding: 0.25rem;
        background: transparentize($c-helion-blue, 0.9);
        flex-grow: 0;
        border-radius: 2px;
        overflow: hidden;
        min-width: 3rem;

        &:hover {
          cursor: pointer;
        }

        app-icon .icon__72,
        app-icon .icon__72 svg {
          width: 100%;
          height: 100%;
        }
      }

      &__caption {
        @media (max-width: $breakpoint-small) {
          padding-left: 0;
        }

        flex-grow: 1;
        text-align: left;
        padding-left: 1rem;
        display: flex;

        &:hover {
          cursor: pointer;
        }

        > div {
          p {
            margin: 0;
            font-size: 14px;
            line-height: normal;
          }

          p:first-child {
            font-weight: bold;
          }

          p:last-child {
            font-weight: 300;
          }
        }
      }

      &__select {
        flex-grow: 0;
        min-width: 90px;
      }

      &__tooltip {
        @media (max-width: $breakpoint-small) {
          order: -1;
        }

        img {
          display: inline-block;
          vertical-align: 0;
          margin: 0 0.5rem;
        }
      }
    }
  }

  .offer-preview-em__flat-rate-info-box {
    @include div-list-bullets;
    p {
      margin-bottom: 0;
    }
  }
}
