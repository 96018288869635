@mixin loading-spinner {
  .loading-spinner {
    @keyframes app-loader--spinner-animation {
      to {
        transform: rotate(360deg);
      }
    }

    &__spinner:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      margin-top: -20px;
      margin-left: -20px;
      border-radius: 50%;
      border: 4px solid #ccc;
      border-top-color: #89a;
      animation: app-loader--spinner-animation 0.6s linear infinite;
    }

    &__spinner--button:before {
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border: 2px solid rgba(255, 255, 255, 0.25);
      border-top-color: rgba(255, 255, 255, 0.75);
    }
  }
}
